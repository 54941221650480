import type { MetaFormat } from "@/controllers/category-controller.server"
import type { FormattedPostProps } from "@/controllers/post-controller.server"
import type { Colors } from "@/lib/colors-category"
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node"

import { json, Link, useLoaderData, useLocation } from "@remix-run/react"
import { Fragment } from "react"

import { Header } from "@/components/header"
import { InfiniteScroll } from "@/components/infinite-scroller"
import { Organization } from "@/components/structured-data/organization"
import { CategoryController } from "@/controllers/category-controller.server"
import { ContextController } from "@/controllers/context-controller.server"
import { GQCAController } from "@/controllers/gqca-controller.server"
import { PostController } from "@/controllers/post-controller.server"
import { cacheHeaders } from "@/lib/cacheHeaders"
import { cn } from "@/lib/utils"

const _headers = cacheHeaders({ browser: 0, cdn: 60 * 2, swr: 2678400 })
export const headers = () => _headers

interface LoaderResponse {
  nextPage?: number
  category: {
    title: string
    description?: string
    content?: any
    slug: string
    color?: Colors
  }
  meta: MetaFormat
  posts: FormattedPostProps[]
  gqcaPosts?: any[]
}

export const handle = { hydrate: true }

export const loader = async ({ context: { payload }, request, params: { slug } }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const pageParam = parseInt(url.searchParams.get("page") ?? '1')
  const page = pageParam > 0 ? pageParam : 1
  const postController = PostController(payload)

  async function loaderLatestNews() {
    const categoryController = CategoryController(payload)
    const category = await categoryController.findBySlug(slug as string)
    const { docs, nextPage } = await postController.findLatestsNews({ page })
    return {
      nextPage,
      category: {
        title: category.title,
        description: category.description,
        slug: category.slug,
        color: category.color,
      },
      meta: categoryController.formatMeta(category),
      posts: postController.formatPosts(docs, 'ultimas-noticias')
    }
  }

  async function loaderCategory() {
    const categoryController = CategoryController(payload)
    const category = await categoryController.findBySlug(slug as string)
    const { docs, nextPage } = await postController.findByCategoryId({ page, categoryId: category.id })

    const gqcaPosts = slug === 'animes' && await GQCAController().getPosts({ limit: 10 })

    return {
      nextPage,
      category: {
        title: category.title,
        description: category.description,
        slug: category.slug,
        color: category.color,
      },
      meta: categoryController.formatMeta(category),
      posts: postController.formatPosts(docs),
      gqcaPosts
    }
  }

  async function loaderContext() {
    const contextController = ContextController(payload)
    const context = await contextController.findBySlug(slug as string)
    const { docs, nextPage } = await postController.findByContextId({ contextId: context.id, page })
    return {
      nextPage,
      category: {
        title: context.title,
        content: context.content,
        slug: context.slug
      },
      meta: contextController.formatMeta(context),
      posts: postController.formatPosts(docs)
    }
  }

  async function loadData() {
    switch (slug) {
      case "ultimas-noticias":
        return await loaderLatestNews()
      case "series":
      case "filmes":
      case "games":
      case "animes":
        return await loaderCategory()
      default:
        return await loaderContext()
    }
  }

  const response = await loadData() as LoaderResponse

  return json(response, { headers: _headers })
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return []
  const { image, canonical, title, description } = data.meta
  return [
    { tagName: "link", rel: "canonical", href: canonical },
    { title: title },
    { name: "title", content: title },
    { name: "description", content: description },
    { property: "twitter:title", content: title },
    { property: "twitter:description", content: description },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:image", content: image?.url },
    { property: "twitter:image:width", content: image?.width },
    { property: "twitter:image:height", content: image?.height },
    { property: "twitter:url", content: canonical },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:url", content: canonical },
    { property: "og:image", content: image?.url },
    { property: "og:image:width", content: image?.width },
    { property: "og:image:height", content: image?.height },
    { property: "og:type", content: "website" },
    {
      "script:ld+json": {
        "@context": "http://schema.org",
        "@type": "CollectionPage",
        "name": title,
        "description": description,
        "url": canonical,
        "publisher": {
          ...Organization,
        }
      }
    }
  ]
}

export default function Element() {
  const { category, posts, nextPage, gqcaPosts } = useLoaderData<typeof loader>()
  const { pathname } = useLocation()

  return (
    <div className="flex flex-col lg:flex-row gap-x-8 justify-center">
      <div className="max-w-prose container mx-0 mt-4 lg:mt-8 flex flex-col gap-8">
        <Header {...category} items={[{ ...category }]} />
        <InfiniteScroll initialPosts={posts} initialNextPage={nextPage} key={pathname} />
      </div>
      {gqcaPosts && (
        <section className="flex flex-col gap-4 mb-8 lg:max-w-xs mt-16 pt-3 px-4 lg:px-0">
          <Link to="https://gqcanimes.com.br/">
            <h2 className={cn("text-2xl font-medium border-b pb-2 mb-2 text-pink-500 hover:opacity-80")}>GQCAnimes</h2>
          </Link>
          <div className="flex flex-col gap-4">
            {gqcaPosts.map(({ id, title, media, pathname, excerpt }, index) => (
              <Fragment key={id}>
                {index > 5 ? (
                  <article className="border-b pb-4">
                    {media && (
                      <Link to={pathname}>
                        <img className="rounded-lg" src={media.url} alt={title} width={media.width} height={media.height} loading="lazy" />
                      </Link>
                    )}
                    <h3 className="font-medium hover:opacity-80 text-xl mt-3 mb-1"><Link to={pathname} dangerouslySetInnerHTML={{ __html: title }} /></h3>
                    <p className="text-sm text-primary/80" dangerouslySetInnerHTML={{ __html: excerpt }} />
                  </article>
                ) : (
                  <article className="grid grid-cols-12 items-center gap-2 border-b pb-4">
                    <h3 className={cn("col-span-7 font-medium hover:opacity-80 text-lg lg:text-base")}><Link to={pathname} dangerouslySetInnerHTML={{ __html: title }} /></h3>
                    {media && (
                      <Link to={pathname} className="col-span-5">
                        <img className="rounded-lg" src={media.url} alt={title} width={media.width} height={media.height} />
                      </Link>
                    )}
                  </article>
                )}
              </Fragment>
            ))}
          </div>
        </section>
      )}
    </div>
  )
}
